import React, { useState, useEffect, useRef, useMemo } from "react";
import moment from 'moment/min/moment-with-locales';
import { config } from 'Constants';
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Menu } from 'primereact/menu';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import deLocale from '@fullcalendar/core/locales/de';

import NeuesSpiel from 'components/NeuesSpiel';
import SpielerListe from 'components/spielerListe';

import logo from "../logo.8102127e.png";

export default function SpieleListe(props) {
  const [spiele, setSpiele] = useState([]);
  const [events, setEvents] = useState([]);
  const [spiel_id, setSpiel_id] = useState();
  const [newDialog, setNewDialog] = useState(false);
  const [showSpieler, setShowSpieler] = useState(false);
  const [currentView, setCurrentView] = useState(localStorage.getItem('currentView'));
  const calendar = useRef();
  const menu = useRef(null);
  const items = [
    {
      label: 'Spieler verwalten',
      icon: 'pi pi-pencil',
      command: () => { setShowSpieler(true) }
    },
  ];

  const aktuellesDatum = useMemo(() => {
    moment.locale("de"); 
    return moment().format("L")
  }, []);

  useEffect(() => {
    fetch(
      config.API_URL + "/spiel"
    )
      .then((data) => data.json())
      .then((data) => setSpiele(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let events = []
    for (let i = 0; i < spiele.length; i++) {
      let spiel = spiele[i]
      events.push({ id: spiel.spiel_id, start: spiel.datum, ...spiel });
    }
    setEvents(events)
  }, [spiele]);

  useEffect(() => {
    localStorage.setItem('currentView', currentView);

    switch (currentView) {
      case "today":
        calendarApi().changeView('listDay', new Date());
        break;
      case "":
      case null:
      case "null":
        calendarApi().changeView('listWeek');
        break;
      default:
        calendarApi().changeView(currentView)
        break;
    }
  }, [currentView]);

  const rightToolbarTemplate = () => {
    return (
      <div className="p-d-block p-jc-right">
        <Button
          label="Neues Spiel"
          icon="pi pi-plus"
          className="p-button-primary p-mr-1 p-mb-1 p-button-raised p-button-rounded p-d-block"
          onClick={() => { setSpiel_id(null); setNewDialog(true) }}
        />
        <Button
          label="Statistik"
          icon="pi pi-chart-bar"
          className="p-button-primary p-mr-1 p-mb-1 p-button-raised p-button-rounded p-d-block"
          onClick={() => { props.history.push("/statistik");  }}
        />
      </div>
    );
  };

  const calendarApi = () => {
    return calendar.current.getApi();
  }

  const hideDialog = () => {
    setNewDialog(false);
  }

  const onDelete = (spiel_id) => {
    let alt = [...spiele]
    for (let i = 0; i < spiele.length; i++) {
      if (parseInt(spiele[i].spiel_id) === parseInt(spiel_id)) {
        alt.splice(i, 1);
        break;
      }
    }
    setSpiele(alt)
  };

  const rowBodyTemplate = (rowData, props) => {
    return (
      <Button type="button" icon="pi pi-pencil" className="p-button-rounded p-button-text"/>
    )
  };

  const refreshSpiel = (spiel_id) => {
    if (!spiel_id) {
      fetch(config.API_URL + "/spiel")
        .then((data) => data.json())
        .then((data) => setSpiele(data));
      return;
    }
    fetch(
      config.API_URL + "/spiel/" + spiel_id
    )
      .then((data) => data.json())
      .then((data) => {
        let alt = [...spiele]
        for (let i = 0; i < spiele.length; i++) {
          if (parseInt(spiele[i].spiel_id) === parseInt(spiel_id)) {
            alt[i] = data;
            setSpiele(alt)
            return;
          }
        }
        alt.push(data);
        setSpiele(alt)
      });
  }

  const renderEventContent = (eventInfo) => {
    let spiel = eventInfo.event.extendedProps;
    return (
      <div key={spiel.spiel_id} className="p-dataview-list p-grid p-nogutter ">
        <div className="p-col-11 p-md-8">
          {renderPaarung(spiel)}
        </div>
        <div className="p-col-1">
          {rowBodyTemplate(spiel)}
        </div>
      </div>
    );
  }

  const renderPaarung = (data) => {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-grid p-nogutter p-col-12">
          <div className="p-col-6 p-mb-2">
            {data.heim_1}, {data.heim_1_vorname}
            {data.heim_2 && <> / <br />{data.heim_2}, {data.heim_2_vorname} </>}
          </div>
          {
            (data.ergebnis || []).map((satz, i) => {
              let className = (satz.punkte_heim > satz.punkte_gast) ? "p-text-bold" : "p-text-normal";
              return (<div className="p-field p-col-1 p-mb-0"><div className={className}>{satz.punkte_heim}</div></div>)
            })
          }
        </div>
        <div className="p-grid p-nogutter p-col-12">
          <div className="p-col-6 p-mb-0">
            {data.gast_1}, {data.gast_1_vorname}
            {data.gast_2 && <> / <br />{data.gast_2}, {data.gast_2_vorname} </>}
          </div>
          {
            (data.ergebnis || []).map((satz, i) => {
              let className = (satz.punkte_gast > satz.punkte_heim) ? "p-text-bold" : "p-text-normal";
              return (<div className="p-field p-col-1 p-mb-0 "><div className={className}>{satz.punkte_gast}</div></div>)
            })
          }
        </div>
      </div>
    )
  }


  return (
    <>
      <header className="App-header p-m-0">
        <Toolbar
          style={{ background: "#ffffff" }}
          left={<img src={logo} className="App-logo" alt="logo" />}
          right={rightToolbarTemplate}
          className="p-m-0"
        />
      </header>

      <div className="p-text-right p-mb-0">
        <Menu model={items} popup ref={menu} id="popup_menu" />
        <Button className="p-button-secondary p-button-text" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
      </div>
      <div className="p-text-center p-mb-5">
        <Button
          label={aktuellesDatum}
          className="p-button-primary p-mr-1 p-button-sm"
          onClick={(e) => { setCurrentView("today") }}
        />
        <Button
          label="Tag"
          className="p-button-primary p-mr-1 p-button-sm"
          onClick={(e) => { setCurrentView("listDay") }}
        />
        <Button
          label="Woche"
          className="p-button-primary p-mr-1 p-button-sm"
          onClick={(e) => { setCurrentView("listWeek") }}
        />
        <Button
          label="Monat"
          className="p-button-primary p-button-sm"
          onClick={(e) => { setCurrentView("listMonth") }}
        />
      </div>


      <FullCalendar
        eventContent={renderEventContent}
        events={events}
        eventBackgroundColor="green"
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        locale={deLocale}
        initialView="listWeek"
        ref={calendar}
        views={{
          listDay: { buttonText: 'Tag' },
          listWeek: { buttonText: 'Woche' },
          listMonth: { buttonText: 'Monat' }
        }}
        eventClick={(e) => { setSpiel_id(e.event.id); setNewDialog(true) }}
        headerToolbar={{
          start: 'title',
          center: null,
          end: 'prev,next'
        }}
        height="auto"
        allDaySlot={false}
      />

      <Dialog
        visible={newDialog}
        header="Spiel verwalten"
        modal
        maximizable
        closeOnEscape={false}
        position="top"
        onHide={hideDialog}
      >
        <NeuesSpiel
          spiel_id={spiel_id}
          onSaveSuccess={() => { refreshSpiel(spiel_id); setNewDialog(false) }}
          onDelete={() => { onDelete(spiel_id); setNewDialog(false) }}
        />
      </Dialog>

      <Dialog
        visible={showSpieler}
        header="Spieler verwalten"
        modal
        maximizable
        position="top"
        onHide={() => setShowSpieler(false)}
      >
        <SpielerListe />
      </Dialog>
    </>
  );

}
