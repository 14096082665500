import React, { useState, useEffect, useRef, useCallback } from "react";
import { config } from 'Constants';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import ProgressSpinner from 'components/ProgressSpinner';

export default function NeuesSpiel({ spiel_id, startDatum, onSaveSuccess, onDelete, ...props }) {
  const [datum, setDatum] = useState(startDatum);
  const [spieler, setSpieler] = useState([]);
  const [ergebnis, setErgebnis] = useState([]);
  const [id_heim_1, setId_heim_1] = useState();
  const [id_heim_2, setId_heim_2] = useState();
  const [id_gast_1, setId_gast_1] = useState();
  const [id_gast_2, setId_gast_2] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [busy, setBusy] = useState(false);

  const cal = useRef(null);
  const toast = useRef(null);

  /**
   * Lesen aller Spieler
   */
  useEffect(() => {
    fetch(
      config.API_URL + "/spieler?order=name"
    )
      .then((data) => data.json())
      .then((data) => {
        let spielerliste = [];
        data.forEach((spieler) => {
          spielerliste.push({ 'label': spieler.name + (spieler.vorname ? ", " + spieler.vorname : ""), 'value': spieler.spieler_id })
        });
        setSpieler(spielerliste)
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  /** 
   * Spiel lesen 
   */
  useEffect(() => {
    if (!spiel_id) {
      setDatum(new Date());
      return
    }
    fetch(
      config.API_URL + "/spiel/" + spiel_id
    )
      .then((data) => data.json())
      .then((data) => {
        setDatum(new Date(data.datum));
        setId_heim_1(data.spieler_id_heim_1);
        setId_heim_2(data.spieler_id_heim_2);
        setId_gast_1(data.spieler_id_gast_1);
        setId_gast_2(data.spieler_id_gast_2);
        setErgebnis(data.ergebnis);

        // Wenn bereits Punkte eingetragen, direkt zum Ergebnis
        if (data.ergebnis) {
          setActiveIndex(1);
        }
      });
  }, [spiel_id]);

  /**
   * Spiel speichern
   */
  const speichern = async () => {
    let data = {
      'spiel_id': spiel_id,
      'datum': datum,
      'spieler_id_heim_1': id_heim_1,
      'spieler_id_heim_2': id_heim_2,
      'spieler_id_gast_1': id_gast_1,
      'spieler_id_gast_2': id_gast_2,
      'ergebnis': ergebnis
    };

    setBusy(true);
    if (spiel_id > 0) {
      fetch(config.API_URL + "/spiel/" + spiel_id, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then((text) => {
          setBusy(false);
          showSuccess();
          if (onSaveSuccess) onSaveSuccess()
        })
        .catch((error) => {
          console.log("Error: ", error)
        })
    }
    else {
      fetch(config.API_URL + "/spiel", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then((data) => data.json())
        .then((response) => {
          setBusy(false);
          showSuccess();
          if (onSaveSuccess) onSaveSuccess()
        })
        .catch((error) => {
          console.log("Error: ", error)
        })
    }
  }

  const showSuccess = useCallback(() => {
    toast.current.show({ severity: 'success', summary: 'Speichern erfolgreich', life: 2000 });
  }, []);

  const getSatz = useCallback((satz) => {
    for (let i = 0; i < (ergebnis || []).length; i++) {
      if (parseInt(ergebnis[i].satz) === satz) {
        return ergebnis[i];
      }
    }
    return null;
  }, [ergebnis]);

  const onPunkteChange = useCallback(
    (satz, a, punkte) => {
      let alt = []

      if (ergebnis) {
        alt = [...ergebnis]

        for (let i = 0; i < alt.length; i++) {
          if (parseInt(alt[i].satz) === satz) {
            alt[i][a] = punkte;
            setErgebnis(alt)
            return;
          }
        }
      }
      if (!punkte) {
        return
      }

      let satzergebnis = {
        "satz": satz,
        "punkte_heim": null,
        "punkte_gast": null
      }
      satzergebnis[a] = punkte
      alt.push(satzergebnis);
      setErgebnis(alt)
    },
    [ergebnis],
  );

  /**
   * rendert ein Eingabefeld für Punkte eines Satzes
   */
  const eingabePunkte = useCallback(
    (satz, a) => {
      let s = getSatz(satz);
      let punkte = null;

      if (s) {
        punkte = s[a];
      }
      return (
        <InputNumber value={punkte} onValueChange={(e) => onPunkteChange(satz, a, e.value)} min={0} size={1} className="p-mr-2" />
      )
    },
    [getSatz, onPunkteChange],
  );


  const getSpieler = (id) => {
    for (let i = 0; i < (spieler || []).length; i++) {
      if (parseInt(spieler[i].value) === parseInt(id)) {
        return spieler[i].label
      }
    }
    return null;
  }

  const speichernErlaubt = () => {
    if (!datum) {
      return false
    }
    return true;
  }

  const loeschen = () => {
    confirmDialog({
      message: 'Soll das Spiel wirklich gelöscht werden?',
      header: 'Spiel löschen',
      icon: 'pi pi-question-circle',
      acceptClassName: 'p-button-danger',
      accept: () => {
        fetch(config.API_URL + "/spiel/" + spiel_id, {
          method: 'DELETE'
        })
          .then(() => {
            if (onDelete) onDelete()
          });
      }
    });
  };

  return (
    <>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="p-mt-2">
        <TabPanel header="Spieldaten">
          <div className="p-fluid p-grid p-formgrid">
            <div className="p-field p-col-12 p-col-align-center">
              <label htmlFor="datum"> Datum</label>
              <Calendar
                ref={cal}
                id="datum"
                dateFormat="dd.mm.yy"
                value={datum}
                onChange={(e) => setDatum(e.value)}
                showTime
                required
                showIcon
                hideOnDateTimeSelect
              />
            </div>
            <div className="p-field p-col-12">
              <label htmlFor="heim">Heim</label>
              <Dropdown
                value={id_heim_1}
                options={spieler}
                onChange={(e) => setId_heim_1(e.value)}
                required
                showClear
                className="p-mb-1"
              />
              <Dropdown
                value={id_heim_2}
                options={spieler}
                onChange={(e) => setId_heim_2(e.value)}
                showClear
              />
            </div>
            <div className="p-field p-col-12">
              <label htmlFor="gast">Gast</label>
              <Dropdown
                value={id_gast_1}
                options={spieler}
                onChange={(e) => setId_gast_1(e.value)}
                required
                showClear
                className="p-mb-1"
              />
              <Dropdown
                value={id_gast_2}
                options={spieler}
                onChange={(e) => setId_gast_2(e.value)}
                showClear
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Ergebnis">
          <div className="p-fluid p-grid p-mt-0">
            <div className="p-field p-col-12 p-text-center p-m-0">
              {getSpieler(id_heim_1)}
              {id_heim_2 > 0 && <> / {getSpieler(id_heim_2)} </>}
            </div>

            <div className="p-grid p-col-12 p-justify-center">
              <div className="p-col-2">
                {eingabePunkte(1, "punkte_heim")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(2, "punkte_heim")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(3, "punkte_heim")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(4, "punkte_heim")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(5, "punkte_heim")}
              </div>
            </div>

            <div className="p-col-12 p-text-center p-m-0">
              -
            </div>

            <div className="p-grid p-col-12 p-justify-center p-mt-0 p-mb-0">
              <div className="p-col-2">
                {eingabePunkte(1, "punkte_gast")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(2, "punkte_gast")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(3, "punkte_gast")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(4, "punkte_gast")}
              </div>
              <div className="p-col-2">
                {eingabePunkte(5, "punkte_gast")}
              </div>
            </div>

            <div className="p-field p-col-12 p-text-center p-m-0">
              {getSpieler(id_gast_1)}
              {id_gast_2 > 0 && <> / {getSpieler(id_gast_2)}</>}
            </div>
          </div>

        </TabPanel>
      </TabView>

      <div className="p-text-right">
        <Button
          icon="pi pi-check"
          label="Speichern"
          className="p-button-primary p-button-rounded p-button-raised"
          loading={busy}
          disabled={!speichernErlaubt()}
          onClick={() => speichern()}
        />
        {spiel_id &&
          <Button
            icon="pi pi-trash"
            label="Löschen"
            className="p-button-danger p-button-rounded p-button-raised p-ml-2"
            loading={busy}
            onClick={() => loeschen()}
          />
        }
      </div>

      <ProgressSpinner isVisible={busy} />

      <Toast ref={toast} position="bottom-left" />
    </>
  )
}