import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrimeReact, { addLocale, locale } from "primereact/api";
import SpieleListe from "components/spieleListe";
import Statistik from "components/Statistik";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "App.css";

function App() {
  // active ripple effect
  PrimeReact.ripple = true;

  addLocale("de", {
    accept: "Ja",
    reject: "Nein",
    firstDayOfWeek: 1,
    dayNames: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    dayNamesMin: ["S", "M", "D", "M", "D", "F", "S"],
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
    today: "Heute",
    clear: "Clear",
  });

  locale("de");

  return (
    <Router>
      <Route key="statistik" exact path="/statistik" component={Statistik} />
      <Route key="root" exact path="/" component={SpieleListe} />
    </Router>
  );
}

export default App;
