import React, { useState, useEffect, useRef } from "react";
import { config } from 'Constants';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';

export default function SpielerListe() {
  const [spieler, setSpieler] = useState([]);
  const [name, setName] = useState("");
  const [vorname, setVorname] = useState("");
  const originalRows = useRef({});
  const toast = useRef(null);


  useEffect(() => {
    fetch(config.API_URL + "/spieler?order=name"
    )
      .then((data) => data.json())
      .then((data) => { setSpieler(data) }
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const rowEditorValidator = (e) => {
    // Pflichtfelder prüfen
    return true;
  }

  const onEditorValueChange = (props, value) => {
    let updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    setSpieler(updatedProducts);
  }

  const inputTextEditor = (props) => {
    return <InputText type="text" value={props.rowData[props.field]} onChange={(e) => onEditorValueChange(props, e.target.value)} />;
  }

  const inputName = () => {
    return <InputText type="text" value={name} style={{ width: '100%' }} onChange={(e) => setName(e.target.value)} />;
  }
  const inputVorname = () => {
    return <InputText type="text" value={vorname} style={{ width: '100%' }} onChange={(e) => setVorname(e.target.value)} />;
  }

  const buttonSave = () => {
    return (
      <Button
        icon="pi pi-save"
        className="p-button-rounded p-button-text"
        onClick={() => {
          fetch(
            config.API_URL + "/spieler", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              "name": name,
              "vorname": vorname
            })
          }
          )
            .then((data) => data.json())
            .then((data) => {
              toast.current.show({ severity: 'success', summary: 'Speichern erfolgreich', life: 2000 });
              let products = [...spieler];
              products.push(data);
              setSpieler(products);
              setName("");
              setVorname("");
            })
            .catch((e) => { console.debug("Error") });
        }}
      />
    )
  }

  const onRowEditInit = (event) => {
    originalRows.current[event.index] = { ...spieler[event.index] };
  }

  const onRowEditCancel = (event) => {
    let products = [...spieler];
    products[event.index] = originalRows.current[event.index];
    delete originalRows.current[event.index];
    setSpieler(products);
  }

  const onRowEditSave = (event) => {
    fetch(
      config.API_URL + "/spieler/" + event.data.spieler_id,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "name": event.data.name,
          "vorname": event.data.vorname
        })
      }
    )
      .then((data) => toast.current.show({ severity: 'success', summary: 'Speichern erfolgreich', life: 2000 }) )
      .catch((e) => { onRowEditCancel(event); });
  }

  return (
    <>
      <div>
        <div className="card">
          <DataTable
            value={spieler}
            editMode="row"
            dataKey="spieler_id"
            className="p-datatable-sm"
            paginator rows={8}
            onRowEditInit={onRowEditInit}
            onRowEditCancel={onRowEditCancel}
            onRowEditSave={onRowEditSave}
            rowEditorValidator={rowEditorValidator}
          >
            <Column field="spieler_id" header="ID" style={{ width: '25px' }} className="p-text-right p-d-none" />
            <Column field="name" sortable header="Nachname" footer={inputName} editor={(props) => inputTextEditor(props)} />
            <Column field="vorname" sortable header="Vorname" footer={inputVorname} editor={(props) => inputTextEditor(props)} />
            <Column className="p-text-right" rowEditor headerStyle={{ width: '7rem' }} footer={buttonSave} bodyStyle={{ textAlign: 'center' }} />
          </DataTable>
        </div>
      </div>
      <Toast ref={toast} position="bottom-left" />
    </>
  );
}
