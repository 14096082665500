import React, { useState, useEffect } from "react";
import { config } from 'Constants';
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { TabView, TabPanel } from 'primereact/tabview';

import logo from "../logo.8102127e.png";
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from "primereact/column";

export default function Statistik(props) {
  const [spiele, setSpiele] = useState([]);
  const [stats, setStats] = useState([]);
  const [statsDoppel, setStatsDoppel] = useState([]);

  const rightToolbarTemplate = () => {
    return (
      <div className="p-d-block p-jc-right">
        <Button
          label="zurück"
          icon="pi pi-list"
          className="p-button-primary p-mr-1 p-mb-1 p-button-raised p-button-rounded p-d-block"
          onClick={() => { props.history.push("/") }}
        />
      </div>
    );
  };

  // Alle Spiele lesen
  useEffect(() => {
    fetch(
      config.API_URL + "/spiel"
    )
      .then((data) => data.json())
      .then((data) => setSpiele(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let stats = {}

    // Schleife über alle Spiele
    for (let i = 0; i < spiele.length; i++) {
      let spiel = spiele[i]

      // Doppel überspringen
      if (spiel.spieler_id_heim_2 || spiel.spieler_id_gast_2) {
        continue;
      }

      // Kein Ergebnis eingetragen => Überspringen
      if (spiel.ergebnis.length <= 0) {
        continue;
      }

      if (!stats[spiel.spieler_id_heim_1]) {
        stats[spiel.spieler_id_heim_1] = {
          name: spiel.heim_1 + ", " + spiel.heim_1_vorname,
          matches: {
            gesamt: 0,
            g: 0,
            v: 0
          },
          punkte: {
            gesamt: 0,
            g: 0,
            v: 0
          },
          saetze: {
            gesamt: 0,
            g: 0,
            v: 0
          }
        }
      }
      if (!stats[spiel.spieler_id_gast_1]) {
        stats[spiel.spieler_id_gast_1] = {
          name: spiel.gast_1 + ", " + spiel.gast_1_vorname,
          matches: {
            gesamt: 0,
            g: 0,
            v: 0
          },
          punkte: {
            gesamt: 0,
            g: 0,
            v: 0
          },
          saetze: {
            gesamt: 0,
            g: 0,
            v: 0
          }
        }
      }

      // Schleife über die Sätze
      let saetze_h = 0;
      let saetze_g = 0;
      for (let j = 0; j < spiel.ergebnis.length; j++) {
        const satz = spiel.ergebnis[j];

        stats[spiel.spieler_id_heim_1].punkte.g += parseInt(satz.punkte_heim);
        stats[spiel.spieler_id_heim_1].punkte.v += parseInt(satz.punkte_gast);
        stats[spiel.spieler_id_gast_1].punkte.g += parseInt(satz.punkte_gast);
        stats[spiel.spieler_id_gast_1].punkte.v += parseInt(satz.punkte_heim);

        if (parseInt(satz.punkte_heim) > parseInt(satz.punkte_gast)) {
          stats[spiel.spieler_id_heim_1].saetze.g++;
          stats[spiel.spieler_id_gast_1].saetze.v++;
          saetze_h++;
        }
        if (parseInt(satz.punkte_heim) < parseInt(satz.punkte_gast)) {
          stats[spiel.spieler_id_heim_1].saetze.v++;
          stats[spiel.spieler_id_gast_1].saetze.g++;
          saetze_g++;
        }
      }

      // Matches
      if (saetze_h > saetze_g) {
        stats[spiel.spieler_id_heim_1].matches.g++;
        stats[spiel.spieler_id_gast_1].matches.v++;
      }
      else if (saetze_h < saetze_g) {
        stats[spiel.spieler_id_heim_1].matches.v++;
        stats[spiel.spieler_id_gast_1].matches.g++;
      }
      else {
        // Unentschieden nach Sätzen wird nicht in die Matches-Wertung aufgenommen
        continue
      }

      stats[spiel.spieler_id_heim_1].matches.gesamt++;
      stats[spiel.spieler_id_gast_1].matches.gesamt++;

      stats[spiel.spieler_id_heim_1].matches.q = stats[spiel.spieler_id_heim_1].matches.g / (stats[spiel.spieler_id_heim_1].matches.gesamt);
      stats[spiel.spieler_id_gast_1].matches.q = stats[spiel.spieler_id_gast_1].matches.g / (stats[spiel.spieler_id_gast_1].matches.gesamt);

      stats[spiel.spieler_id_heim_1].matches.q = stats[spiel.spieler_id_heim_1].matches.q.toFixed(2);
      stats[spiel.spieler_id_gast_1].matches.q = stats[spiel.spieler_id_gast_1].matches.q.toFixed(2);
    }
    setStats(Object.values(stats));
    statistikDoppel();
  }, [spiele]);

  const neuerSpieler = (name, vorname) => {
    return {
      name: name + ", " + vorname,
      matches: {
        gesamt: 0,
        g: 0,
        v: 0
      },
      punkte: {
        gesamt: 0,
        g: 0,
        v: 0
      },
      saetze: {
        gesamt: 0,
        g: 0,
        v: 0
      }
    }
  }

  const statistikDoppel = () => {
    let stats = {}

    // Schleife über alle Spiele
    for (let i = 0; i < spiele.length; i++) {
      let spiel = spiele[i]

      // Einzel überspringen
      if (!spiel.spieler_id_heim_2 || !spiel.spieler_id_gast_2) {
        continue;
      }

      // Kein Ergebnis eingetragen => Überspringen
      if (spiel.ergebnis.length <= 0) {
        continue;
      }

      if (!stats[spiel.spieler_id_heim_1]) {
        stats[spiel.spieler_id_heim_1] = neuerSpieler(spiel.heim_1, spiel.heim_1_vorname)
      }
      if (!stats[spiel.spieler_id_heim_2]) {
        stats[spiel.spieler_id_heim_2] = neuerSpieler(spiel.heim_2, spiel.heim_2_vorname)
      }
      if (!stats[spiel.spieler_id_gast_1]) {
        stats[spiel.spieler_id_gast_1] = neuerSpieler(spiel.gast_1, spiel.gast_1_vorname)
      }
      if (!stats[spiel.spieler_id_gast_2]) {
        stats[spiel.spieler_id_gast_2] = neuerSpieler(spiel.gast_2, spiel.gast_2_vorname)
      }

      // Schleife über die Sätze
      let saetze_h = 0;
      let saetze_g = 0;
      for (let j = 0; j < spiel.ergebnis.length; j++) {
        const satz = spiel.ergebnis[j];

        stats[spiel.spieler_id_heim_1].punkte.g += parseInt(satz.punkte_heim);
        stats[spiel.spieler_id_heim_1].punkte.v += parseInt(satz.punkte_gast);
        stats[spiel.spieler_id_heim_2].punkte.g += parseInt(satz.punkte_heim);
        stats[spiel.spieler_id_heim_2].punkte.v += parseInt(satz.punkte_gast);
        stats[spiel.spieler_id_gast_1].punkte.g += parseInt(satz.punkte_gast);
        stats[spiel.spieler_id_gast_1].punkte.v += parseInt(satz.punkte_heim);
        stats[spiel.spieler_id_gast_2].punkte.g += parseInt(satz.punkte_gast);
        stats[spiel.spieler_id_gast_2].punkte.v += parseInt(satz.punkte_heim);

        if (parseInt(satz.punkte_heim) > parseInt(satz.punkte_gast)) {
          stats[spiel.spieler_id_heim_1].saetze.g++;
          stats[spiel.spieler_id_gast_1].saetze.v++;
          stats[spiel.spieler_id_heim_2].saetze.g++;
          stats[spiel.spieler_id_gast_2].saetze.v++;
          saetze_h++;
        }
        if (parseInt(satz.punkte_heim) < parseInt(satz.punkte_gast)) {
          stats[spiel.spieler_id_heim_1].saetze.v++;
          stats[spiel.spieler_id_gast_1].saetze.g++;
          stats[spiel.spieler_id_heim_2].saetze.v++;
          stats[spiel.spieler_id_gast_2].saetze.g++;
          saetze_g++;
        }
      }

      // Matches
      if (saetze_h > saetze_g) {
        stats[spiel.spieler_id_heim_1].matches.g++;
        stats[spiel.spieler_id_gast_1].matches.v++;
        stats[spiel.spieler_id_heim_2].matches.g++;
        stats[spiel.spieler_id_gast_2].matches.v++;
      }
      else if (saetze_h < saetze_g) {
        stats[spiel.spieler_id_heim_1].matches.v++;
        stats[spiel.spieler_id_gast_1].matches.g++;
        stats[spiel.spieler_id_heim_2].matches.v++;
        stats[spiel.spieler_id_gast_2].matches.g++;
      }
      else {
        // Unentschieden nach Sätzen wird nicht in die Matches-Wertung aufgenommen
        continue
      }

      stats[spiel.spieler_id_heim_1].matches.gesamt++;
      stats[spiel.spieler_id_gast_1].matches.gesamt++;
      stats[spiel.spieler_id_heim_2].matches.gesamt++;
      stats[spiel.spieler_id_gast_2].matches.gesamt++;

      stats[spiel.spieler_id_heim_1].matches.q = stats[spiel.spieler_id_heim_1].matches.g / (stats[spiel.spieler_id_heim_1].matches.gesamt);
      stats[spiel.spieler_id_gast_1].matches.q = stats[spiel.spieler_id_gast_1].matches.g / (stats[spiel.spieler_id_gast_1].matches.gesamt);

      stats[spiel.spieler_id_heim_1].matches.q = stats[spiel.spieler_id_heim_1].matches.q.toFixed(2);
      stats[spiel.spieler_id_gast_1].matches.q = stats[spiel.spieler_id_gast_1].matches.q.toFixed(2);

      stats[spiel.spieler_id_heim_2].matches.q = stats[spiel.spieler_id_heim_2].matches.g / (stats[spiel.spieler_id_heim_2].matches.gesamt);
      stats[spiel.spieler_id_gast_2].matches.q = stats[spiel.spieler_id_gast_2].matches.g / (stats[spiel.spieler_id_gast_2].matches.gesamt);

      stats[spiel.spieler_id_heim_2].matches.q = stats[spiel.spieler_id_heim_2].matches.q.toFixed(2);
      stats[spiel.spieler_id_gast_2].matches.q = stats[spiel.spieler_id_gast_2].matches.q.toFixed(2);
    }
    setStatsDoppel(Object.values(stats));
  }

  const summeBodyTemplate = (rowData, props) => {
    return <div>{rowData[props.columnKey].g + rowData[props.columnKey].v}</div>
  }

  const plusMinusBodyTemplate = (rowData, props) => {
    let diff = rowData[props.columnKey].g - rowData[props.columnKey].v;
    return <div>{diff > 0 ? "+" + diff : diff}</div>
  }

  const onSortEinzel = (event) => {
    return onSort(event, stats)
  }
  const onSortDoppel = (event) => {
    return onSort(event, statsDoppel)
  }

  const onSort = (event, stats) => {
    let data = [...stats];
    data.sort((data1, data2) => {
      let result = null;
      let value1 = 0;
      let value2 = 0;

      switch (event.field) {
        case "matches.diff":
          value1 = data1["matches"].g - data1["matches"].v;
          value2 = data2["matches"].g - data2["matches"].v;
          break;
        case "saetze.diff":
          value1 = data1["saetze"].g - data1["saetze"].v;
          value2 = data2["saetze"].g - data2["saetze"].v;
          break;
        case "punkte.diff":
          value1 = data1["punkte"].g - data1["punkte"].v;
          value2 = data2["punkte"].g - data2["punkte"].v;
          break;

        default:
          value1 = data1[event.field].g + data1[event.field].v;
          value2 = data2[event.field].g + data2[event.field].v;
          break;
      }

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * -1 * result);
    });

    return data;
  }

  let headerGroup = <ColumnGroup>
    <Row>
      <Column header="Name" sortable field="name" rowSpan={3} />
    </Row>
    <Row>
      <Column header="Matches" colSpan={3} headerStyle={{ textAlign: 'left' }} />
      <Column header="Sätze" colSpan={2} />
      <Column header="Spiele" colSpan={2} />
    </Row>
    <Row>
      <Column header="Gesamt" sortable sortField="matches" sortFunction={onSortEinzel} />
      <Column header="+/-" sortable sortField="matches.diff" sortFunction={onSortEinzel} />
      <Column header="Quotient" sortable sortField="matches.q" />
      <Column header="Gesamt" sortable sortField="saetze" sortFunction={onSortEinzel} />
      <Column header="+/-" sortable sortField="saetze.diff" sortFunction={onSortEinzel} />
      <Column header="Gesamt" sortable sortField="punkte" sortFunction={onSortEinzel} />
      <Column header="+/-" sortable sortField="punkte.diff" sortFunction={onSortEinzel} />
    </Row>
  </ColumnGroup>;

let headerGroupDoppel = <ColumnGroup>
    <Row>
      <Column header="Name" sortable field="name" rowSpan={3} />
    </Row>
    <Row>
      <Column header="Matches" colSpan={3} headerStyle={{ textAlign: 'left' }} />
      <Column header="Sätze" colSpan={2} />
      <Column header="Spiele" colSpan={2} />
    </Row>
    <Row>
      <Column header="Gesamt" sortable sortField="matches" sortFunction={onSortDoppel} />
      <Column header="+/-" sortable sortField="matches.diff" sortFunction={onSortDoppel} />
      <Column header="Quotient" sortable sortField="matches.q" />
      <Column header="Gesamt" sortable sortField="saetze" sortFunction={onSortDoppel} />
      <Column header="+/-" sortable sortField="saetze.diff" sortFunction={onSortDoppel} />
      <Column header="Gesamt" sortable sortField="punkte" sortFunction={onSortDoppel} />
      <Column header="+/-" sortable sortField="punkte.diff" sortFunction={onSortDoppel} />
    </Row>
  </ColumnGroup>;

  return (
    <>
      <header className="App-header p-m-0">
        <Toolbar
          style={{ background: "#ffffff" }}
          left={<img src={logo} className="App-logo" alt="logo" />}
          className="p-m-0"
          right={rightToolbarTemplate}
        />
      </header>
      <TabView>
        <TabPanel header="Einzel">
          <DataTable
            value={stats}
            headerColumnGroup={headerGroup}
            className="p-datatable-sm"
            paginator rows={50}
            sortField="matches.q" sortOrder={-1}
            autoLayout
          >
            <Column field="name" />
            <Column body={summeBodyTemplate} columnKey="matches" />
            <Column body={plusMinusBodyTemplate} columnKey="matches" />
            <Column field="matches.q" columnKey="matches" />
            <Column body={summeBodyTemplate} columnKey="saetze" />
            <Column body={plusMinusBodyTemplate} columnKey="saetze" />
            <Column body={summeBodyTemplate} columnKey="punkte" />
            <Column body={plusMinusBodyTemplate} columnKey="punkte" />
          </DataTable>
        </TabPanel>
        <TabPanel header="Doppel">
          <DataTable
            value={statsDoppel}
            headerColumnGroup={headerGroupDoppel}
            className="p-datatable-sm"
            paginator rows={50}
            sortField="matches.q" sortOrder={-1}
            autoLayout
          >
            <Column field="name" />
            <Column body={summeBodyTemplate} columnKey="matches" />
            <Column body={plusMinusBodyTemplate} columnKey="matches" />
            <Column field="matches.q" columnKey="matches" />
            <Column body={summeBodyTemplate} columnKey="saetze" />
            <Column body={plusMinusBodyTemplate} columnKey="saetze" />
            <Column body={summeBodyTemplate} columnKey="punkte" />
            <Column body={plusMinusBodyTemplate} columnKey="punkte" />
          </DataTable>

        </TabPanel>
      </TabView>
    </>
  );

}
