import React from "react";
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner as PrimeProgressSpinner } from 'primereact/progressspinner';

export default function ProgressSpinner({ isVisible }) {

  return (
    <Dialog
      visible={isVisible}
      closable={false}
      modal
      closeOnEscape={false}
    >
      <PrimeProgressSpinner />
    </Dialog>
  )
}